<template>
  <div id="app">
    <img style="display: none;" alt="Vue logo" src="./assets/logo.png">
         <span style="display: none;">
         </span>
          <router-view></router-view>
          <main_back_button/>
  </div>
</template>

<script>
//import theme_switch from './components/theme_switch.vue';
import './styles_inputs_and_buttons.css';
import './styles_forms.css';
import './styles_global.css';
import main_back_button from './components/main_back_button.vue';


export default {
  name: 'EPMO',
  components: {
    //theme_switch
    main_back_button,
  },

}
</script>



<style>
/* Import Material Design icons CSS */
@import '~@mdi/font/css/materialdesignicons.min.css';

#app {
  text-align: center;
  /*
  margin-top: 58px;
  */
  margin-left: auto;
  margin-right: auto;
}

*{
  padding: 0;
  margin: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Orbitron:wght@400..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Teko:wght@300..700&display=swap');


* {
  font-family: "Roboto", sans-serif;
}

body{
   background-color: var(--base-200);
   background-color: var(--base-200);
}

:root {
  --primary: #00F0A0;
  --primary: #9966FF;
  --primary: #f568e0;
  --primary-dark: #de35c5;
  --secondary: #2f24ea;
  --secondary-dark: #1a12b0;
  --accent: rgba(0, 204, 68, 0.2);
  --neutral: #3d4451;
  --base-100: #2a2a2a;;
  --base-200: #1e1e1e;
  --base-230: #212121;  
  --base-240: #212121;  
  /*
  --base-200: #f5f6f8;
  */
  --base-250: #1a1919; 
  --base-280: #e0e0e0;
  --base-300: #ffffff;
  --dark-mode-base-300: #ffffff;
  --base-330: #474747;
  --base-400: #ffffff;
  --contrast: #ffffff;
  --contrast: #ebebeb;
  --contrast_light: #BEBEBE;
  --contrast_light: rgb(190, 190, 190);
  --rating_star: #ffc13b;
  --online-green: #00cc44;
  --Gradient: linear-gradient(to right, #434343 0%, black 100%);
  --Gradient: linear-gradient(-225deg, #FF057C 0%, #8D0B93 50%, #321575 100%);
  --Gradient:  radial-gradient(circle at 52.1% -29.6%, rgb(144, 17, 105) 0%, rgb(51, 0, 131) 100.2%);
  --Gradient: linear-gradient(to right, #434343 0%, black 100%);
  --glow-shade: 0 4px 20px rgba(47, 36, 234, 0.5);
  --font-size-small: 14px;
  --font-size-medium: 17px;
  --font-size-large: 22px;
  --font-size-extra-large: 28px;
  --font-size-top: 24px;

  --border-radius-main: 10px;
  --border-radius-main-2x: 20px;
  --border-radius-main-3x: 30px;

  --spacing-main-2x: 25px;
  --spacing-main: 20px;
  --spacing-main-mid: 15px;
  --spacing-main-half: 10px;
  --spacing-main-half-half: 5px;
  --spacing-main-half-half-half: 2.5px;

  --box-shadow-1: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  --box-shadow-2: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  --box-shadow-2: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  --box-shadow-3:  rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  --box-shadow-3: 0 4px 20px rgba(0, 0, 0, 0.1);
  --box-shadow-4:  0 4px 8px rgba(0, 0, 0, 0.2);

  --main_width: 100%; 
  --main_width_fixed: 100%; 
  --main_width_fixed_2: 90%; 

  --info-accent: rgba(0, 204, 68, 0.2);
  --info-accent-boxshadow: rgba(0, 204, 68, 0.5) 0px 0px 0px 1px;

  --error-accent: rgba(255, 0, 0, 0.1);
  --error-accent-boxshadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 1px;

  --warning-accent: rgba(255, 255, 0, 0.2);
  --warning-accent-boxshadow: rgba(255, 255, 0, 0.5) 0px 0px 0px 1px;
  
  --success-accent: rgba(42, 210, 9, 0.1);
  --success-accent-boxshadow: rgba(42, 210, 9, 0.5) 0px 0px 0px 1px;

}

:root.light-mode {
  --primary: #2f24ea;
  --primary-dark: #130bad;
  --secondary: #00F0A0;
  --secondary-dark: #0ebc82;
  --secondary: #f568e0;
  --secondary-dark: #de35c5;
  /*
  --glow-shade: 0 4px 20px rgba(0, 240, 160, 0.5);
  */
  --glow-shade: 0 4px 20px rgba(245, 104, 224, 0.5);
  --neutral: #f5f5f5;
  --base-100: #ffffff;
  --base-200: #eeeeee;
  --base-200: #f5f5f5;
  --base-230: #e0e0e0;
  --base-240: #f0f0f0;
  --base-250: #f5f5f5;
  --base-280: #d0d0d0;
  --base-300: #000000;
  --contrast: #212121;
  --contrast_light: #757575;
  --rating_star: #ffb300;
  --online-green: #388e3c;
  --box-shadow-1: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px, rgba(0, 0, 0, 0.05) 0px 2px 6px 2px;
  --box-shadow-3: 0 4px 20px rgba(0, 0, 0, 0.05);
  --box-shadow-4: 0 4px 8px rgba(0, 0, 0, 0.1);
}



</style>
